import {useStaticQuery, graphql} from "gatsby"

export const GetList = () => {
    const {allMdx} = useStaticQuery(
        graphql`
        query listYachts {
              allMdx(filter: {fileAbsolutePath: {regex: "src/yachts//"}}) {
                edges {
                  node {
                    frontmatter {
                      slug
                      title
                      velocidad
                      eslora
                      manga
                      capacidad
                      thumbnail {
                        childImageSharp {
                          fluid(maxWidth: 790) {
                                ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        `
    )
    return allMdx.edges;
}
