import React, {Component, useState} from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import style from "./marine.module.scss"
import imgPage from "../images/headers/Logos_Ale_Marine_Blue.svg"
import Header from "../components/header";
import Img from "gatsby-image"

import {isIE} from "react-device-detect"

import {Player, ControlBar} from "video-react"
import "video-react/dist/video-react.css"
import VideoMarine from "../video/marine_video.mp4"

// import LogoPrincess from "../images/icons/princess.svg"
import LogoShareAtSea from "../images/icons/ShareatSEA.svg"
import {GetList} from "../hooks/yachtsList";
import Foto1 from "../images/marine/foto1.jpg";
import Foto2 from "../images/marine/foto2.jpg";
import Foto3 from "../images/marine/foto3.jpg";
// import Azimut from "../images/marine/azimut.png";
import Fim from "../images/marine/FIM.png";
import Form from "../components/form";


// import VideoCovidMarine from "../video/sanitizacion_marine.mp4";

export default ({data}) => {


    const marineList = GetList();
    var [selected, setSelected] = useState('laPaz');
    var [showing, setShowing] = useState(false);
    const IE = isIE;
    console.log(IE)

    function showList() {
        setShowing(!showing)
    }

    function changeSelected(select, e) {
        setSelected(select);
        setShowing(false)
    }

    const extraInfo =
        [
            {name: '', mail: 'info@ale.mx', phone: '55 4202 0920'},
        ];

    return (
        <Layout footer='marine2' extraInfo={extraInfo}>

            <SEO title="Marine"/>
            <Header LogoPage={imgPage}/>


            <div className={style.marinePortada}>
                <Img fluid={data.portada.childImageSharp.fluid}/>
            </div>
            <Form image={Foto2} options={['Share At Sea: Fractional Navigation', 'Princess Yachts: New Yacht']}/>

            <div className={style.marineInfo}>
                <h1>Ale Marine</h1>
                <p>Ale Marine abarca todos los aspectos de la navegación con el manejo de un experimentado equipo de
                    profesionales que tienen como objetivo que su experiencia en el mar sea una verdadera tranquilidad,
                    disfrutando sólo lo bueno que un yate ofrece y dejando en nuestras manos los aspectos técnicos,
                    legales
                    y humanos.</p>
                <p>Si su deseo es adquirir un yate en su totalidad, ALE Marine es distribuidor exclusivos de Azimut
                    Yachts México el astillero italiano de mayor renombre y reputación, así como de Fabbrica Italiana
                    Motoscafi, experimentado astillero Italiano que produce vanguardistas modelos de yates que combinan
                    lujo y deportividad.</p>
            </div>

            <div className={style.marineProducts}>

                <div className={style.marineProduct}>
                    <img src={Fim} alt={'FIM'}/>
                    <p><b>Fabrica Italiana Motoscafi</b></p>
                    <p>Con sus modelos 340 Regina, 470 Regina y 490 Contessa, FIM produce barcos enfocados en realizar
                        actividades “al fresco”, con amplios espacios al exterior.</p>
                    <a href="https://www.fimotoscafi.com"
                       rel="noopener noreferrer" target='_blank'>Conozca Más</a>
                </div>

                {/*<div className={style.marineProduct}>
                    <img src={Azimut} alt={'Azimut México'}/>
                    <p><b>Azimut Yachts México</b></p>
                    <p>Con sus colecciones Atlantis, Magellano, Flybridge, S, Verde y Grande, Azimut ofrece el yate
                        adecuado para cada concepto.</p>
                    <a href="https://www.azimutyachts.com"
                       rel="noopener noreferrer" target='_blank'>Conozca Más</a>
                </div>*/}

                <div className={style.marineProduct}>
                    <LogoShareAtSea/>
                    <p><b>Share at Sea</b></p>
                    <p>Es la división de navegación fraccional de Ale Marine. Con el soporte y más de 50 años de
                        experiencia de Aerolíneas Ejecutivas.</p>
                    <a href="http://www.shareatsea.com/" target="_blank" rel="noopener noreferrer">Conozca Más</a>
                </div>
            </div>

            <div className={style.marineOur}>
                <h2>Nuestra flota</h2>

                <div className={style.items}>
                    {marineList.map((item) => {
                        return (
                            <div className={IE ? `${style.ie} ${style.item}` : `${style.noie} ${style.item}`}
                                 key={item.node.frontmatter.slug}>
                                <div className={style.left}>
                                    <h2>{item.node.frontmatter.title}</h2>
                                    <Img style={{maxHeight: '270px'}}
                                         fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}/>
                                </div>
                                <div className={style.right}>
                                    <p><span>Velocidad</span><span>{item.node.frontmatter.velocidad}</span></p>
                                    <p><span>Eslora</span><span>{item.node.frontmatter.eslora}</span></p>
                                    <p><span>Manga</span><span>{item.node.frontmatter.manga}</span></p>
                                    <p><span>Capacidad de agua</span><span>{item.node.frontmatter.capacidad}</span></p>
                                    <Link to={item.node.frontmatter.slug}>Conozca Más</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className={style.marineMap}>
                <h2>Destinos</h2>
                <div className={showing ? `${style.titles} ${style.showing}` : style.titles}>
                    <button className={selected === "laPaz" ? style.active : ''}
                            onClick={(e) => changeSelected('laPaz', e)}>
                        <span> </span>La Paz, BC
                    </button>
                    <button className={selected === "miami" ? style.active : ''}
                            onClick={(e) => changeSelected('miami', e)}>
                        <span> </span>Miami, Florida
                    </button>
                    <button className={selected === "baha" ? style.active : style.off}
                            onClick={(e) => changeSelected('baha', e)}>
                        <span> </span>Bahamas, Nasáu
                    </button>
                    <button className={selected === "cancun" ? style.active : style.off}
                            onClick={(e) => changeSelected('cancun', e)}>
                        <span> </span>Cancún, Quintana Roo
                    </button>
                    <button className={selected === "ixta" ? style.active : style.off}
                            onClick={(e) => changeSelected('ixta', e)}>
                        <span> </span>Ixtápa, Zihuatanejo
                    </button>
                    <span> </span>
                    <button className={style.responsiveButton} onClick={(e) => showList()}></button>
                </div>
                <div className={style.map}>
                    <Img fluid={data.mapa.childImageSharp.fluid}/>
                    <button onClick={(e) => changeSelected('laPaz', e)}
                            className={selected === "laPaz" ? `${style.laPaz} ${style.active}` : style.laPaz}></button>
                    <button onClick={(e) => changeSelected('miami', e)}
                            className={selected === "miami" ? `${style.miami} ${style.active}` : style.miami}></button>
                    <button onClick={(e) => changeSelected('baha', e)}
                            className={selected === "baha" ? `${style.baha} ${style.active}` : style.baha}></button>
                    <button onClick={(e) => changeSelected('cancun', e)}
                            className={selected === "cancun" ? `${style.cancun} ${style.active}` : style.cancun}></button>
                    <button onClick={(e) => changeSelected('ixta', e)}
                            className={selected === "ixta" ? `${style.ixta} ${style.active}` : style.ixta}></button>
                </div>
            </div>
            <Form align="bottom" image={Foto2}
                  options={['Share At Sea: Fractional Navigation', 'Princess Yachts: New Yacht']}/>
            <div className={style.marineImages}>

                <div className={style.marineVideo}>
                    <img className={style.img} src={Foto1} alt="Share at Sea"/>
                    <PlayerMarine/>
                </div>

                <div className={style.title}>
                    <p>Share at Sea</p>
                </div>
                <div className={style.infoBlock}>
                    <img src={Foto2} alt="share at sea"/>
                    <div className={style.info}>
                        <ul>
                            <p>Share at Sea es el primer programa de navegación fraccional que le permite disfrutar, sin
                                molestias ni preocupaciones, la propiedad de un yate; realizando la inversión en parte
                                justa del tiempo que usted necesita anualmente, y con costos transparentes y sin
                                sorpresas. Transformamos la navegación para que el mar sea un placer, no un dolor de
                                cabeza.</p>
                        </ul>
                    </div>
                </div>

                <div className={style.title}>
                    <p>Un programa claro como el agua</p>
                </div>
                <div className={style.infoBlock}>
                    <div className={style.info}>
                        <ul>
                            <p>Administración total, llave en mano, por Ale Marine. Todos los costos fijos contemplados
                                e incluidos:</p>

                            <li><p>Mantenimiento preventivo y correctivo</p></li>
                            <li><p>Renovación constante de interiores</p></li>
                            <li><p>Tripulaciones certificadas y entrenadas</p></li>
                            <li><p>Resguardo y costos legales</p></li>
                            <li><p>Costos fijos estipulados y transparentes</p></li>
                        </ul>
                    </div>
                    <img src={Foto3} alt="servicios de tráfico"/>
                </div>

            </div>

        </Layout>
    )

}


export class PlayerMarine extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            vidStatus: false,
            player: null
        };
        this.startVideo = this.startVideo.bind(this);
        this.onEnded = this.onEnded.bind(this);
    }


    startVideo() {
        this.setState({vidStatus: true})
        this.player.play();
    }

    onEnded() {
        this.setState({vidStatus: false})
    }

    render() {
        return (
            <Player
                poster={Foto1}
                onEnded={this.onEnded}
                className={this.state.vidStatus === true ? `${style.video} ${style.active}` : `${style.video}`}
                src={VideoMarine}
                ref={player => {
                    this.player = player;
                }}
            >
                <ControlBar disableCompletely={false}/>
                <button className={style.button} onClick={this.startVideo}>Play Video</button>
            </Player>
        )
    }

}

/*export class PopCovidMarine extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            vidStatus: false,
            player: null,
            active: true,
            playing: false
        };

        this.startVideo = this.startVideo.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.closeAlert = this.closeAlert.bind(this)
    }

    closeAlert() {
        this.setState({active: false})
        this.player.pause();
    }

    startVideo() {
        this.setState({vidStatus: true});
        this.player.play();
    }

    onEnded() {
        this.closeAlert();
    }

    render() {
        return (
            <div className={this.state.active ? `${style.popUpVideo} ${style.active}` : style.popUpVideo}>
                <h2>YA ESTAMOS OPERANDO EN LAS MARINAS EN LA PAZ, LORETO, BAHAMAS, CANCÚN E IXTAPA.</h2>
                <Player
                    onEnded={this.onEnded}
                    className={this.state.vidStatus === true ? `${style.video} ${style.active}` : `${style.video}`}
                    src={VideoCovidMarine}
                    autoPlay={false}
                    loop={false}
                    ref={player => {
                        this.player = player;
                    }}
                >
                    {/!*<button className={style.close} onClick={this.closeAlert}>x</button>*!/}
                    <ControlBar disableCompletely={false}/>
                    <button className={style.button} onClick={this.startVideo}>Play Video</button>
                </Player>
            </div>
        )
    }
}*/

export const query = graphql`
    query Images {
    portada: file(relativePath: {eq: "marine/portada.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    mapa: file(relativePath: {eq: "marine/mapa.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}`

